<template>
  <div>
    <v-data-table :items="items" :headers="headers">
      <template v-slot:item.actions="{ item }">
       <v-btn @click="goToChangeDetails(item)" text color="green">Show</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ChangeOverview",
  data() {
    return {
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Name', value: 'newObject.name' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters('change', {
      items: 'activeUnitItems'
    }),
  },
  methods: {
    goToChangeDetails(item) {
      this.$router.push({ name: 'change-details', params: { changeId: item.id }})
    }
  }
};
</script>

<style scoped>

</style>
