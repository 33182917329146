import { render, staticRenderFns } from "./ChangesOverviewPage.vue?vue&type=template&id=5e749ade&scoped=true&"
import script from "./ChangesOverviewPage.vue?vue&type=script&lang=js&"
export * from "./ChangesOverviewPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e749ade",
  null
  
)

export default component.exports